




















































import { Component } from 'vue-property-decorator';

import BaseSettingsPage from './BaseSettingsPage.vue';
import { Stepper, Step } from '@/components/stepper';
import { SmsService } from '@/lib/services';
import { SmsCounts, SmsSettings } from '@/lib/services/sms';
import SmsTexts from '@/components/settings/SmsTexts.vue';

@Component({
  components: {
    Stepper,
    Step,
    SmsTexts,
  },
})
export default class SmsSettingsPage extends BaseSettingsPage {
  private loaded: boolean = false;
  private committed: boolean = true;
  private settings: SmsSettings | null = null;
  private counts: SmsCounts | null = null;

  async mounted(): Promise<void> {
    [this.settings, this.counts] = await Promise.all([
      SmsService.getSettings(),
      SmsService.counts(),
    ]);
    this.loaded = true;
  }

  async onEnableChange(): Promise<void> {
    if (!this.settings) return;
    this.committed = false;

    try {
      this.settings = await SmsService.updateSettings(this.settings);
      await this.$store.dispatch(
        'settings/setSmsEnabled',
        this.settings.enabled,
      );
      this.committed = true;
    } catch (e) {
      this.$toaster.error(
        this.$tc('messages.error.update.settings'),
        (e as any).message,
      );
    }
  }
}
